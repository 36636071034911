import * as React from "react"
import Layout from "../components/common/layout"
import SearchLocation from "../components/common/search-location"
import Heading from "../components/common/heading" 
import Hero from "../components/hero"
import Title from "../components/title"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import FourthCard from "../components/fourth-card"

// markup 
const locations = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  return (
    <Layout>
      <Helmet
        title='Locations page'
      />
      <Hero container={true} background="bg-blue-white"  data={hero.data[0]} noSlider={true}></Hero>
      <SearchLocation />
      <Heading title='Newest locations' background='bg-white'/>
      <FourthCard data={fourthCard.data[0]} background="bg-white" halfColMobile={true} noSpacingBottom={true} ></FourthCard>
      <Heading title='Featured locations' background='bg-white' />
      <FourthCard data={fourthCard.data[1]} background="bg-white" halfColMobile={true} ></FourthCard>
      <Title data={dataTitle.data[0]} background="bg-white" ></Title>
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["locations"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            
            cards {
              id
              title
              image
              link
            }
          }
        }
      }
    }
  }
`
export default locations
